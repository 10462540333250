import React from "react";
import Button from "../widget/button/Button";

interface LandingProps {}

function Landing(props: LandingProps): React.ReactElement {
    return (
        <div className="landing grid-center">
            <section className="flex align-center gap25">
                {window["config"].LOGO_URL && <img className="border-r5" src={window["config"].LOGO_URL} alt="Logo" />}
                <img className="border-r5" src="/images/logo.svg" alt="Logo" />

                <hr className="v h-if-small" />

                <div className="flex column gap20">
                    <div>
                        <h1>
                            Bienvenue sur {window["config"].APP_NAME} {window["config"].APP_VERSION} {window["config"].POWERED_BY || ""}
                        </h1>
                        <p>Le monde de l'IA s'ouvre à vous !</p>
                    </div>

                    <Button text="Se connecter" link="/connect" />
                </div>
            </section>
        </div>
    );
}

export default Landing;
