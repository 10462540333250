import { use } from "marked";
import React, { useEffect } from "react";

interface ButtonProps {
    text: string;
    link?: string;
    className?: string | "bg-primary";
    disabled?: boolean;
    children?: React.ReactNode;
    tooltip?: string;
    onClick?: (evn: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

export default function Button(props: ButtonProps): React.ReactElement {
    const [className, setClassName] = React.useState<string>(props.className || "");

    const clickBtn = (evn: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        if (props.onClick) {
            props.onClick(evn);
        }
    };

    useEffect(() => {
        setClassName(props.className || "");
    }, [props.className]);

    return (
        <>
            {props.link ? (
                <a
                    className={`custom-c-btn border-r5 flex align-center b-shadow-small ${className} ${props.disabled ? "disabled" : ""}`}
                    onClick={clickBtn}
                    href={props.link}
                    title={props.tooltip}
                >
                    {props.children} <span>{props.text}</span>
                </a>
            ) : (
                <button
                    className={`custom-c-btn border-r5 flex align-center b-shadow-small ${className}`}
                    onClick={clickBtn}
                    disabled={props.disabled}
                    title={props.tooltip}
                >
                    {props.children} <span>{props.text}</span>
                </button>
            )}
        </>
    );
}
