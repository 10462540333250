import React from "react";
import ReactDOM from "react-dom/client";

import Landing from "../../components/landing/Landing";
import GlobalStateProvider from "../../components/store/store";

ReactDOM.createRoot(document.body).render(
    <React.StrictMode>
        <App></App>
    </React.StrictMode>,
);

function App() {
    return (
        <>
            <GlobalStateProvider>
                <></>
                <Landing />
            </GlobalStateProvider>
        </>
    );
}
